import OverviewIcon from "src/assets/images/Laptop.svg";
import UserIcon from "src/assets/images/Users.svg";
import StoreFrontIcon from "src/assets/images/Store_front.svg";
import FileXIcon from "src/assets/images/File_x.svg";
import FileSearchIcon from "src/assets/images/File_search.svg";
import NotificationIcon from "src/assets/images/notification.svg";


type Props = {
  name: string;
  path: string;
  icon: string;
  dropdown: boolean;
  children?: {
    name: string;
    path: string;
  }[];
};

export const SIDENAVS: Props[] = [
  {
    name: "Overview",
    path: "/overview",
    icon: OverviewIcon,
    dropdown: false,
  },
  {
    name: "Merchant Users",
    path: "/merchant-users",
    icon: UserIcon,
    dropdown: false,
  },
  {
    name: "Merchants",
    path: "/merchants",
    icon: StoreFrontIcon,
    dropdown: false,
  },
  {
    name: "IdentityPass",
    path: "/IdentityPass/api-calls",
    icon: OverviewIcon,
    dropdown: true,
    children: [
      {
        name: "API Calls",
        path: "/IdentityPass/api-calls",
      },
      {
        name: "Bulk Verifications",
        path: "/IdentityPass/bulk-verifications",
      },
      {
        name: "Pricing",
        path: "/IdentityPass/pricing",
      },
      {
        name: "Manual Check",
        path: "/identity-pass/manual-check",
      },
    ],
  },
  {
    name: "IdentityRadar",
    path: "/identity-radar/customer-profile",
    icon: OverviewIcon,
    dropdown: true,
    children: [
      {
        name: "Customer Profile",
        path: "/identity-radar/customer-profile",
      },
      {
        name: "Subscription History",
        path: "/identity-radar/subscription-history",
      },
    ],
  },
  {
    name: "Background Check",
    path: "/background-check/requests",
    icon: OverviewIcon,
    dropdown: true,
    children: [
      // {
      //   name: "Candidates",
      //   path: "/background-check/candidates",
      // },
      {
        name: "Requests",
        path: "/background-check/requests",
      },
      {
        name: "Checks",
        path: "/background-check/checks",
      },
      {
        name: "Pricing",
        path: "/background-check/pricing",
      },
      
    ],
  },
  {
    name: "Role Management",
    path: "/role-management",
    icon: FileXIcon,
    dropdown: true,
    children:[
      {
        name:'Users',
        path:'/role-management/#'
      },
      {
        name:'Roles',
        path:'/role-management/role'
      }
    ]
  },
  {
    name: "Audit Logs",
    path: "/audit-logs",
    icon: FileSearchIcon,
    dropdown: false,
  },
  {
    name: "Referral Management",
    path: "/referral-management",
    icon: OverviewIcon,
    dropdown: false,
  },
  {
    name: "Policy Template",
    // path: "/policy-template",
    path: "#",
    icon: FileXIcon,
    dropdown: false,
  },
  {
    name: "Prembly Certificates",
    path: "/prembly-certificates",
    icon: FileXIcon,
    dropdown: false,
  },
  {
    name: "Annoucement",
    path: "/announcement",
    icon: FileXIcon,
    dropdown: false,
  },
  {
    name: "Notification",
    path: "/notification",
    icon: NotificationIcon,
    dropdown: false,
  },
];
