import { FunctionComponent, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ADMIN_PATHS } from "src/constant/path";

import MerchantTransactionProvider from "src/context/merchants/merchantTransaction";
import MerchantUsersProvider from "src/context/merchantUsers/merchantUsers";
import MerchantsProvider from "src/context/merchants/merchants";
import UsersProvider from "src/context/merchants/users";
import OverviewProvider from "src/context/overview";
import NotificationProvider from "src/context/merchants/notification";
import ReferralManagementProvider from "src/context/referralManagement";
import EarningProvider from "src/context/referralManagement/earning";
import TransactionHistoryProvider from "src/context/referralManagement/transactionHistory";

import BackgroundCheckRequestProvider from "src/context/BackgroundCheck/request";
import BackgroundCheckPricingProvider from "src/context/BackgroundCheck/pricing";
import BackgroundCheckPricingConfigProvider from "src/context/BackgroundCheck/pricingConfig";

// import BackgroundCheckCandidateProvider from "src/context/BackgroundCheck/candidate";
import BackgroundCheckViewRequestProvider from "src/context/BackgroundCheck/viewRequest";
import AdminNotificationProvider from "src/context/Notification";
import SubscriptionsProvider from "src/context/IdentityRadar/subscriptions";
// import SubscriptionProvider from "src/context/IdentityRadar/subscription";
import OrganisationSubscriptionsProvider from "src/context/IdentityRadar/organisationSubscriptions";
import SubscriptionProvider from "src/context/IdentityRadar/subscription";
import SubscriptionCardsProvider from "src/context/IdentityRadar/cards";
import CustomersProvider from "src/context/IdentityRadar/customers";
import AuditLogsIndexPage from "../pages/AuditLogs/index";
// import BackgroundCheckCandidateProvider from "src/context/BackgroundCheck/candidate";
import DssChecksPage from "../pages/BackgroundCheck/DssChecksPage";
// import RoleManagement from "../pages/RoleManagement";
import RoleManagement from "src/modules/Admin/pages/RoleManagement";
import Role from "../pages/RoleManagement/Role";
import ManualCheckProvider from "src/context/IdentityPass/manualCheck";

// Overview
const OverviewPage = lazy(() => import("src/modules/Admin/pages/Overview"));
const ViewTransactionsPage = lazy(
  () => import("src/modules/Admin/pages/Overview/ViewTransactions")
);

// MerchantUsers
const MerchantUsersPage = lazy(
  () => import("src/modules/Admin/pages/MerchantUsers")
);

// Merchants
const MerchantPage = lazy(() => import("src/modules/Admin/pages/Merchants"));
const ViewMerchantPage = lazy(
  () => import("src/modules/Admin/pages/Merchants/View")
);

// Prembly Certificates
const PremblyCertificatePage = lazy(
  () => import("src/modules/Admin/pages/PremblyCertificates")
);

// Referral Management
const ReferralManagementPage = lazy(
  () => import("src/modules/Admin/pages/ReferralManagement")
);
const ViewReferralManagementPage = lazy(
  () => import("src/modules/Admin/pages/ReferralManagement/ViewReferrals")
);

// Policy Template
const PolicyTemplatePage = lazy(
  () => import("src/modules/Admin/pages/PolicyTemplate")
);

// BackgroundCheck Template
const BackgroundCheckRequestPage = lazy(
  () => import("src/modules/Admin/pages/BackgroundCheck/Request")
);

// checks
// const BackgroundCheckPage = lazy(
//   () => import("src/modules/Admin/pages/BackgroundCheck/Checks")
// );

// const BackgroundCheckCandidatePage = lazy(
//   () => import("src/modules/Admin/pages/BackgroundCheck/Candidates")
// );
const BackgroundCheckViewRequestPage = lazy(
  () => import("src/modules/Admin/pages/BackgroundCheck/ViewRequestList")
);

// form page
const BackgroundCheckViewRequestDetailsPage = lazy(
  () => import("src/modules/Admin/pages/BackgroundCheck/VerifyFormPage")
);

const BackgroundCheckViewChecksDetailssPage = lazy(
  () => import("src/modules/Admin/pages/BackgroundCheck/ChecksFormPage")
);

const BackgroundCheckPricingPage = lazy(
  () => import("src/modules/Admin/pages/BackgroundCheck/pricing")
);

const BackgroundCheckPricingConfigPage = lazy(
  () => import("src/modules/Admin/pages/BackgroundCheck/pricing/PricingConfig")
);

// Notification Template
const NotificationPage = lazy(
  () => import("src/modules/Admin/pages/Notification")
);

// IdentityRadar
const SubscriptionHistoryPage = lazy(
  () => import("src/modules/Admin/pages/IdentityRadar/SubscriptionHistory")
);
const ViewSubscriptionPage = lazy(
  () => import("src/modules/Admin/pages/IdentityRadar/SubscriptionHistory/View")
);
const CustomerProfilesPage = lazy(
  () => import("src/modules/Admin/pages/IdentityRadar/CustomerProfiles")
);
const ViewCustomerPage = lazy(
  () => import("src/modules/Admin/pages/IdentityRadar/CustomerProfiles/View")
);

// IdentityPass
const ManualCheckPage = lazy(
  () => import ("src/modules/Admin/pages/IdentityPass/ManualCheck/")
)

const AdminRoute: FunctionComponent<Record<string, never>> = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Navigate to="overview" replace />} />
        <Route path={ADMIN_PATHS.OVERVIEW}>
          <Route
            path=""
            element={
              <OverviewProvider>
                <OverviewPage />
              </OverviewProvider>
            }
          />
          <Route
            path="transactions"
            element={
              <OverviewProvider>
                <ViewTransactionsPage />
              </OverviewProvider>
            }
          />
        </Route>
        <Route 
        path={ADMIN_PATHS.AUDIT_LOGS}
        element={
          <MerchantUsersProvider>
            <AuditLogsIndexPage/>
          </MerchantUsersProvider>
        }
        />
        <Route
          path={ADMIN_PATHS.MERCHANT_USERS}
          element={
            <MerchantUsersProvider>
              <MerchantUsersPage />
            </MerchantUsersProvider>
          }
        />
        <Route path={ADMIN_PATHS.MERCHANTS}>
          <Route
            path=""
            element={
              <MerchantsProvider>
                <MerchantPage />
              </MerchantsProvider>
            }
          />
          <Route
            path=":merchantName/:merchantId"
            element={
              <UsersProvider>
                <MerchantTransactionProvider>
                  <NotificationProvider>
                    <ViewMerchantPage />
                  </NotificationProvider>
                </MerchantTransactionProvider>
              </UsersProvider>
            }
          />
        </Route>

        <Route
          path={ADMIN_PATHS.PREMBLY_CERTIFICATES}
          element={<PremblyCertificatePage />}
        />
        <Route>
          <Route path={ADMIN_PATHS.REFERRAL_MANAGEMENT}>
            <Route
              path=""
              element={
                <ReferralManagementProvider>
                  <TransactionHistoryProvider>
                    <ReferralManagementPage />
                  </TransactionHistoryProvider>
                </ReferralManagementProvider>
              }
            />
            <Route
              path="referees/:businessName/:businessId"
              element={
                <EarningProvider>
                  <ViewReferralManagementPage />
                </EarningProvider>
              }
            />
          </Route>
        </Route>

        <Route>
          <Route path={ADMIN_PATHS.POLICY_TEMPLATE}>
            <Route path="" element={<PolicyTemplatePage />} />
          </Route>
        </Route>

        <Route path={ADMIN_PATHS.BACKGROUND_CHECK}>
          <Route
            path=""
            element={
              <BackgroundCheckRequestProvider>
                <NotificationProvider>
                  <BackgroundCheckRequestPage />
                </NotificationProvider>
              </BackgroundCheckRequestProvider>
            }
          />
          <Route
            path="checks"
            element={
              <BackgroundCheckViewRequestProvider>
              <>
                <BackgroundCheckRequestProvider>
                  <NotificationProvider>  
                        <DssChecksPage />
                  </NotificationProvider>
                </BackgroundCheckRequestProvider>
              </>
              </BackgroundCheckViewRequestProvider>

            }
          />
          {/* <Route
            path="candidates"
            element={
              <>
                <BackgroundCheckCandidateProvider>
                  <NotificationProvider>
                    <BackgroundCheckCandidatePage />
                  </NotificationProvider>
                </BackgroundCheckCandidateProvider>
              </>
            }
          /> */}
          <Route
            path="requests"
            element={
              <>
                <BackgroundCheckRequestProvider>
                  {/* <NotificationProvider> */}
                  <BackgroundCheckRequestPage />
                  {/* </NotificationProvider> */}
                </BackgroundCheckRequestProvider>
              </>
            }
          />

          <Route
            path="requests/:requestName/:requestId"
            element={
              <>
                <BackgroundCheckViewRequestProvider>
                  {/* <NotificationProvider> */}
                  <BackgroundCheckViewRequestPage />
                  {/* </NotificationProvider> */}
                </BackgroundCheckViewRequestProvider>
              </>
            }
          />

          {/* verify form page */}

          <Route
            path="requests/:requestName/:requestId/:checkName/:checkId/details"
            element={
              <>
                <BackgroundCheckViewRequestProvider>
                  {/* <NotificationProvider> */}
                  <BackgroundCheckViewRequestDetailsPage />
                  {/* </NotificationProvider> */}
                </BackgroundCheckViewRequestProvider>
              </>
            }
          />
          {/* CHECKS */}

          {/* <Route
            path="checks"
            element={
              <>
                <BackgroundCheckRequestProvider>
                  <NotificationProvider>
                    <BackgroundCheckPage />
                  </NotificationProvider>
                </BackgroundCheckRequestProvider>

              </>
            }
          /> */}
          <Route
            path="requests/:requestName/:requestId/:checkName/:checkId/checks/details"
            element={
              <>
                <BackgroundCheckViewRequestProvider>
                  {/* <NotificationProvider> */}
                  <BackgroundCheckViewChecksDetailssPage />
                  {/* </NotificationProvider> */}
                </BackgroundCheckViewRequestProvider>
              </>
            }
          />

          <Route
            path="pricing"
            element={
              <>
                <BackgroundCheckPricingProvider>
                  <BackgroundCheckPricingPage />
                </BackgroundCheckPricingProvider>
              </>
            }
          />

          <Route
            path="pricing/:merchantName/:merchantId"
            element={
              <>
                <BackgroundCheckPricingConfigProvider>
                  <BackgroundCheckPricingConfigPage />
                </BackgroundCheckPricingConfigProvider>
              </>
            }
          />

        </Route>
        <Route>
          <Route
            path="notification"
            element={
              <AdminNotificationProvider>
                <NotificationPage />
              </AdminNotificationProvider>
            }
          />
        </Route>
        <Route path={ADMIN_PATHS.IDENTITY_RADAR}>
          <Route
            path="subscription-history"
            element={
              <SubscriptionsProvider>
                <SubscriptionHistoryPage />
              </SubscriptionsProvider>
            }
          />
          <Route
            path="subscription-history/:profileName/:subscriptionId/organisation/:organisationId"
            element={
              <OrganisationSubscriptionsProvider>
                <SubscriptionProvider>
                  <SubscriptionCardsProvider>
                    <ViewSubscriptionPage />
                  </SubscriptionCardsProvider>
                </SubscriptionProvider>
              </OrganisationSubscriptionsProvider>
            }
          />
          <Route
            path="customer-profile"
            element={
              <CustomersProvider>
                <CustomerProfilesPage />
              </CustomersProvider>
            }
          />
          <Route
            path="customer-profile/:profileName/organisation/:organisationId"
            element={
              <OrganisationSubscriptionsProvider>
                {/* // <SubscriptionProvider> */}
                {/* // <SubscriptionCardsProvider> */}
                <ViewCustomerPage />
                {/* // </SubscriptionCardsProvider> */}
                {/* // </SubscriptionProvider> */}
              </OrganisationSubscriptionsProvider>
            }
          />
        </Route>
      </Route>
      {/* Identity Pass Route */}
        <Route path={ADMIN_PATHS.IDENTITY_PASS}>
          <Route
              path="manual-check"
              element={
                <ManualCheckProvider>
                  <ManualCheckPage />
                </ManualCheckProvider>
              }
          />
        </Route>
      {/* End of Identity Pass Route */}
      <Route path={ADMIN_PATHS.ROLE_MANAGEMENT}>
      <Route
            path=""
            element={
              <RoleManagement />
            }
          />
          <Route
            path="role"
            element={
              <Role />
            }
          />
      </Route>
      <Route
        path="*"
        element={
          <div
            style={{
              width: "50%",
              margin: "5em auto",
              textAlign: "center",
            }}
          >
            <p>Oopppss, you seem to be lost</p>
          </div>
        }
      />
    </Routes>
  );
};

export default AdminRoute;
