import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";
import { parseQueryParams } from "src/helpers/parseQueryParams";

export const backgroundCheckSlice = createApi({
  reducerPath: "backgroundCheck",
  baseQuery: customFetchBase,
  tagTypes: [

  ],
  endpoints: (builder) => ({
    request: builder.query<
      any,
      {
        // view: number;
        // page: number;
        last_evaluated_key:string;
        search?: string;
        status?: string;
        start_date: string;
        end_date: string;
        business_name?: string;
        business_email?: string;
        kyc_status?: string;
      }
    >({
      query: (params) => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/request`,
        url: "https://api.prembly.com/background-check/background/api/v1/admin/request",
        params: parseQueryParams(params),
      }),
    }),
    candidate: builder.query<
      any,
      {
        view: number;
        page: number;
        search?: string;
        status?: string;
        start_date: string;
        end_date: string;
        business_name?: string;
        business_email?: string;
        kyc_status?: string;
      }
    >({
      query: (params) => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/candidate`,
        url: "https://api.prembly.com/background-check/background/api/v1/admin/candidate",
        params: parseQueryParams(params),
      }),
    }),
    allSubmittedData: builder.query<
      any,
      {
        id: string | number;
      }
    >({
      query: ({id}) => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/request/records/${id}`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/request/records/${id}`,
      }),
    }),
    viewRequest: builder.query<
      any,
      {
        view: number;
        page: number;
        search?: string;
        status?: string;
        start_date: string;
        end_date: string;
        business_name?: string;
        business_email?: string;
        kyc_status?: string;
        id: string | number;
      }
    >({
      query: ({id}) => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/request/${id}/view`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/request/${id}/view`,
      }),
    }),
    uploadDocument: builder.mutation({
      query: ({id, data}) => ({
        method: "POST",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/report/${id}`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/report/${id}`,
        body: data,
      }),
    }),
    getFileUrl: builder.mutation({
      query: ({file}) => ({
        method: "POST",
        url: `https://api.prembly.com/background-check/background/api/v1/file/upload`,
        body:{file:file}, 
      }),
    }),
    convertPDFToImg: builder.mutation({
      query: ({file,file_name}) => ({
        method: "POST",
        // url: `https://4197-102-89-47-251.ngrok-free.app/convert_pdf_to_jpg`,
        url: `https://bgc-file.prembly.com/convert_pdf_to_jpg`,
        body:{file:file, file_name}, 
      }),
    }),
    qaApproval: builder.mutation({
      query: ({id, data}) => ({
        method: "POST",
        url: `https://api.prembly.com/background-check/background/api/v1/admin/report/${id}/qa_approval`,
        body: data,
      }),
    }),
    getEndpoints: builder.query<any, void>({
      query: () => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/all-verification`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/all-verification`,
      }),
    }),
    getUsers: builder.query<any, void>({
      query: () => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/all-users`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/all-users`,
      }),
    }),
    assignUser: builder.mutation({
      query: ({data}) => ({
        method: "POST",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/assign-request-to-va-qa`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/assign-request-to-va-qa`,
        body: data,
      }),
    }),
    getDssChecks:builder.query<any,void>({
      query:()=>({
        method:'GET',
        url:'https://api.prembly.com/background-check/background/api/v1/admin/merchant-checks'
      })
    }),
    downloadSupportDoc: builder.mutation({
      query: ({id}) => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/request/supporting_codument/${id}`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/request/supporting_codument/${id}`,
      }),
    }),
    searchByParam: builder.mutation({
      query: ({phone_number}) => ({
        method: "GET",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/filter-admin-request-data?phone_number=${phone_number}`,
        url: `https://api.prembly.com/background-check/background/api/v1/admin/filter-admin-request-data?phone_number=${phone_number}`,
      }),
    }),
    getAllUserWithID:builder.query<any,void>({
      query:()=>({
        method:'GET',
        url:'/account/profile/all',
      })
    }),
    addVaorQA:builder.mutation<any,{name:string,email:string,user_id:string,role?:'va'|'qa'}>({
      query:(body)=>({
        method:'POST',
        url:`/bgc/accounts/create`,
        body
      })
    }),
    uploadFullReport: builder.mutation({
      query: ({id, data}) => ({
        method: "POST",
        // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/new_report_upload/${id}`,
        url: ` https://api.prembly.com/background-check/background/api/v1/admin/new_report_upload/${id}`,
        body: data,
      }),
    }),
    bgcOrganisations: builder.query<
      any,
      {
        // view: number;
        // page: number;
        // last_evaluated_key:string;
        // search?: string;
        // status?: string;
        // start_date: string;
        // end_date: string;
        // business_name?: string;
        // business_email?: string;
        // kyc_status?: string;
      }
    >({
      query: (params) => ({
        method: "GET",
        // url: "https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/all-organisation",
        url: "https://api.prembly.com/background-check/background/api/v1/admin/all-organisation",
        params: parseQueryParams(params),
      }),
    }),
    bgcCheckList: builder.query<any,void>({
      query: () => ({
        method: "GET", 
        // url: "https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/check_type", 
        url: "https://api.prembly.com/background-check/background/api/v1/admin/check_type", 
      }),
    }),
    pricingCheckSubservices: builder.query<any, {check_id: string, country:string, organisation_id:string, }
    >({
      query: (params) => ({
        method: "GET",
        // url: "https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/check_prices",
        url: "https://api.prembly.com/background-check/background/api/v1/admin/check_prices",
        params: parseQueryParams(params),
      }),
    }),
    pricingConfig: builder.mutation({
      query: ({data}) => ({
        method: "POST",
        // url:`https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/configure-price`,
        url:`https://api.prembly.com/background-check/background/api/v1/admin/configure-price`,
        body: data,
      }),
    }),
    // rerunCheck: builder.query<any, void>({
    //   query: ({id}) => ({
    //     method: "GET",
    //     // url: `https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/background-check-dev/background/api/v1/admin/all-users`,
    //     url: `https://api.prembly.com/background-check/background/api/v1/request/cron/${id}/send`,
    //   }),
    // }),
  }),
});
  
export const {
  useAddVaorQAMutation,
  useGetDssChecksQuery,
  useGetAllUserWithIDQuery,
  useRequestQuery,
  useCandidateQuery,
  useAllSubmittedDataQuery,
  useViewRequestQuery,
  useUploadDocumentMutation,
  useGetFileUrlMutation,
  useConvertPDFToImgMutation,
  useQaApprovalMutation,
  useGetEndpointsQuery,
  useGetUsersQuery,
  useAssignUserMutation,
  useDownloadSupportDocMutation,
  useSearchByParamMutation,
  useUploadFullReportMutation,
  useBgcOrganisationsQuery,
  useBgcCheckListQuery,
  usePricingCheckSubservicesQuery,
  usePricingConfigMutation
  // useRerunCheckQuery,
} = backgroundCheckSlice;
